<script lang="tsx">
import { defineComponent, VNode, PropType } from 'vue'
type renderFn = (data:any) => (VNode | HTMLElement | string)
const props = {
  renderFn: {
    type: Function as PropType<renderFn>,
    default: () => '',
    required: true,
  },
  data: {
    type: Object,
    default: () => {}
  }
}
export default defineComponent({
  props,
  setup(props) {
    return () => (
      <div>
        {props.renderFn(props.data)}
      </div>
    )
  }
})
</script>