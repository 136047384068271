import { ExtractPropTypes, PropType } from 'vue'
import { InputProps as WxInputProps } from '@tarojs/components'
// import {FormItem} from '@nutui/nutui-taro'

export const customNumberInputProps = {

  /* 值 */
  modelValue: {
    type: String,
    default: '',
  },

  /* 文本对齐 */
  textAlign: {
    type: String as PropType<'left' | 'right' | 'center'>,
    default: 'center',
  },

  /* 最大长度 */
  maxLength: {
    type: Number,
    default: 999,
  },

  // 是否限制大于0
  gZero: {
    type: Boolean,
    default: true
  }
}

export type CustomNumberInputProps = Partial<ExtractPropTypes<typeof customNumberInputProps>> & WxInputProps