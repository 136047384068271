<!-- 单选按钮组 -->
<template>
  <div
    class="text-gray-1000"
    :class="[isBlock ? '' : 'flex']"
  >
    <div
      v-for="(option, index) in getRadioOptions"
      :key="index"
    >
      <CustomRadio
        v-bind="option"
        :class="[!isBlock && index ? 'ml-2' : '']"
        :is-group="true"
        @update:modelValue="emits('update:modelValue', $event)"
      />
      <div
        v-if="option.tipText"
        class="text-[#a8a8a8] text-[12px]"
        style="line-height: 20px;"
        :class="[option.tipTextClass]"
      >
        {{ option.tipText }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { radioGroupProps } from './props'
import CustomRadio from '../radio'
import { cloneDeep } from 'lodash-es'

/* types */

/* data */
const props = defineProps(radioGroupProps)
const emits = defineEmits(['update:modelValue'])
const getRadioOptions = computed(() =>
  props.options.map(option => {
    const newOption = cloneDeep(option)
    newOption.modelValue = newOption.value === props.modelValue

    return newOption
  }))
</script>
