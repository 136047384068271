<style lang="less">

</style>
<!-- 页面 -->
<template>
  <div class="mt-4 text-left text-gray-1000 mx-10px">
    <div
      :class="[payType==='wechat'?'border-blue-400':'border-gray-400']"
      class="flex items-center justify-between px-5 pt-4 pb-5 border border-solid rounded "
      @click="tapType('wechat')"
    >
      <div class="flex">
        <basic-icon
          name="gongzhonghao"
          color="#10da6e"
          :size="50"
        />
        <div class="flex flex-col justify-between ml-10px">
          <div class="font-bold">
            微信快捷支付
          </div>
          <div class="mt-10px text-13px">
            打开微信支付程序支付金额
          </div>
        </div>
      </div>
      <nut-icon
        :name=" payType==='wechat'?'checked':'check-normal'"
        :color=" payType==='wechat'?'#448EF6':'#D9D9D9'"
        :size="20"
      />
    </div>
    <div
      v-if="!userStore.isPersonal"
      :class="[payType==='balance'?'border-blue-400':'border-gray-400']"

      class="flex items-center justify-between px-5 pt-4 pb-5 mt-4 border border-solid rounded"
      @click="tapType('balance')"
    >
      <div class="flex">
        <basic-icon
          name="exchange-cny-line"
          color="#fdb44d"
          :size="50"
        />
        <div class="flex flex-col justify-between text-left ml-10px">
          <div class="font-bold">
            储值支付
          </div>
          <div class="text-14px text-common-red font-bold mt-10px">
            账户余额：￥{{ userStore.user?.wallet?.totalBalance || 0 }}
          </div>
          <div class="mt-10px text-13px">
            储值金额支付，可优惠
          </div>
        </div>
      </div>
      <nut-icon
        :name=" payType==='balance'?'checked':'check-normal'"
        :color=" payType==='balance'?'#448EF6':'#D9D9D9'"
        :size="20"
      />
    </div>
    <div class="flex items-center justify-between mt-6 mb-20px">
      <div class="flex flex-col justify-between">
        <div>
          <div class="inline font-bold text-17px">
            共{{ payType==='balance'? props.needMoney:props.originalMoney }}元
          </div>
          <div
            v-if="payType==='balance' && props.showDiscountInfo"
            class="inline text-xs text-gray-700 line-through ml-10px"
          >
            ￥{{ props.originalMoney }}
          </div>
        </div>
        <div
          v-if="payType==='balance' && props.showDiscountInfo"
          class="text-xs"
        >
          共优惠{{ props.discountMoney }}元
        </div>
      </div>
      <div
        class="text-center text-white rounded w-138px leading-45px bg-theme-400"
        @click="submit"
      >
        确认提交
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>


import { ref } from 'vue'
import { paymentMethodProps } from './props'
import { useUserStore } from '@/store/modules/user'
const payType = ref<'wechat' | 'balance'>('wechat')
const userStore = useUserStore()

/* data 数据 */
const emits = defineEmits(['submit', 'update:modelValue'])
const props = defineProps(paymentMethodProps)


/* 方法 */
const submit = () => {
  // fix:当弹框支付页面的金额大于用户余额时，支付报错，然后重新选择数量，支付金额小于用户余额，可以支付，但此时支付页面的弹框的表单得到的支付方式是undefined,所以在提交前更新一次
  emits('update:modelValue', payType.value)
  emits('submit')
}
const tapType = (type:'wechat' | 'balance') => {
  payType.value = type
  emits('update:modelValue', payType.value)
}
</script>