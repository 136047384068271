import { PropType, ExtractPropTypes } from 'vue'
import { RadioProps } from '../radio'
// import {RadioGroup} from '@nutui/nutui-taro'

export const radioGroupProps = {

  /* 复选框配置项 */
  options: {
    type: Array as PropType<(Omit<RadioProps, 'modelValue'> & {tipText?:string, tipTextClass?:string})[]>,
    default: () => ([])
  },
  modelValue: {
    type: [Number, String, Boolean],
    default: ''
  },
  // 是否占一行
  isBlock: {
    type: Boolean,
    default: false
  },
//   direction: {
//     type: String,
//     default: 'vertical'
//   },
//   textPosition: {
//     type: String,
//     default: 'right'
//   }
}

export type RadioGroupProps = Partial<ExtractPropTypes<typeof radioGroupProps>>