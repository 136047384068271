import FundebugVue from 'fundebug-vue'
import * as fundebug from 'fundebug-javascript'
import { App } from 'vue'
import { useEnv } from '../env'
// 过滤常见无关错误
const filterMessages = [
  // ant-design 内部错误项信息，可忽略 https://github.com/ant-design/ant-design/issues/27359
  'ResizeObserver loop completed with undelivered notifications',
  // ant-design 内部错误项信息，可忽略 https://github.com/ant-design/ant-design/issues/15075
  // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
  'ResizeObserver loop limit exceeded',
  '用户名不存在',
  '密码错误',
]

/* fundebug 页面异常监控 */
export function setupJsFundebug(app: App) {
  // console.log('fundebug', fundebug.notifyError)
  fundebug.init({
    apikey: '949fcf4d92905734a512ceda6c195a460ae27529481fa93c8d8181b37481765f',
    releasestage: process.env.NODE_ENV,
    appversion: new Date().toLocaleString(),
    silentDev: true, // 不收集开发环境的错误，当 URL 中含有 localhost 或者 IP 时，判断为开发环境
    silentConsole: useEnv.isDevelopment, // 在开发环境，不重写 console 方法，避免 vue warn 信息多次打印，造成开发时卡死
    monitorHttpBody: true, // 开启 http 请求 body 数据收集
    monitorHttpResponse: true, // 开启 http 返回 body 数据收集
    filters: [ // 忽略特定错误
      { // 因数据量太大，不收集 reference 接口数据
        req: {
          method: /^GET$/,
          url: /global\/reference/
        },
        // hack 此处存在一个坑，在过滤接口请求时，需要结合 req 及 res 两个参数同时使用，否则过滤效果无效
        res: {
          status: /^200$/
        }
      },
      {
        message: new RegExp(`(${filterMessages.join('|')})`)
      },
      {
        req: {
          method: /^GET$/
        },
        res: {
          status: /^401$/
        }
      },
      {
        req: {
          method: /^POST$/
        },
        res: {
          status: /^401$/
        }
      }
    ]
  })
  app.use(new FundebugVue(fundebug))
}


/* 主要用于测试，它发送的错误每次都会报警邮件 */
export const jsDebugTest = (name: string, message: string) => {
  fundebug.test(name, message)
}

/* 会有执行次数限制, 大概4s一次 */
export const jsDebugNotify = (name: string, message: string, option?: Recordable) => {
  fundebug.notify(name, message, {
    metaData: option,
  })
}

/* 接收抛出异常, 也可代替notify */
export const jsDebugNotifyError = (option: Recordable = {}, error: Error = new Error()) => {
  console.log(
    'fundebug', fundebug, error,
    option
  )
  fundebug.notifyError(error, {
    metaData: option
  })
}