import { ExtractPropTypes } from 'vue'

export const paymentMethodProps = {

  originalMoney: {
    type: String,
    default: '0'
  },
  needMoney: {
    type: String,
    default: '0'
  },
  discountMoney: {
    type: String,
    default: '0'
  },
  // 显示优惠信息
  showDiscountInfo: {
    type: Boolean,
    default: true,
  }


}
export type PaymentMethodProps = Partial<ExtractPropTypes<typeof paymentMethodProps>>